<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Item Request'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button 
                class="ml-4 mt-2" 
                color="danger" 
                icon-pack="feather" 
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Selection</h4>
                <hr>
                <br>
                <div class="vx-row mb-6" v-if="readonly && id">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Canvas Request Code</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Territory</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select 
                            :filterable="true" 
                            :options="optionTerritory" 
                            v-model="selectedTerritory" 
                            label="label"
                            :clearable="false"
                            @input="val => { onChangeTerritory(val); }"
                        ></v-select>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select 
                            :filterable="true" 
                            :options="optionWarehouse" 
                            v-model="selectedWarehouse" 
                            label="label"
                            :clearable="false"
                            @input="val => { onChangeWarehouse(val); }"
                        ></v-select>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Sales</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <v-select 
                            :filterable="true" 
                            :options="optionSales" 
                            v-model="selectedSales" 
                            label="label"
                            :clearable="false"
                            @input="val => { onChangeSales(val); }"
                        ></v-select>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Date
                            :value="this.requestDate"
                            @selected="val => { this.requestDate = val }"
                        ></Date>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2" v-if="docStatus != 'New' && id > 0">
                    <h4>Approval Status</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-6">
                        <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                            <thead class="vs-table--thead">
                                <tr>
                                    <th>Approver</th>
                                    <th>Status</th>
                                    <th>Inbox Time</th>
                                    <th>Action Time</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in approvalReason" :key="index" style="padding-bottom: 5px">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.approver }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.status }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.inbox_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.action_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Request Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="50%">SKU Description</th>
                            <th width="15%">HU</th>
                            <th width="10%">Qty UOM</th>
                            <th width="10%">Available Qty</th>
                            <th width="10%">Requested Qty</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select
                                    :filterable="false"
                                    :options="skuLine.option_sku"
                                    v-model="skuLine.selected_sku"
                                    :clearable="false"
                                    @input="val => { onChangeSku(val, index); }"
                                    @search="(search, loading) => { onSearchSku(search, loading, index); }"
                                />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select
                                    :filterable="false"
                                    :options="skuLine.option_hu"
                                    v-model="skuLine.selected_hu"
                                    :clearable="false"
                                    label="unit"
                                    @input="val => { onChangeHu(val, index); }"
                                />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    class="w-full"
                                    v-model="skuLine.qty_uom"
                                    :disabled="true"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input
                                    type="text"
                                    class="w-full"
                                    v-model="skuLine.availableQty"
                                    :disabled="true"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input 
                                    type="number" 
                                    class="w-full" 
                                    v-model="skuLine.qty"
                                    @input="val => { onChangeQty(val, index); }"
                                ></vs-input>
                            </td>
                            <td class="td vs-table--td" style="text-align: center">
                                <div class="vx-input-group flex">
                                    <vs-button 
                                        v-if="skuLines.length > 1" 
                                        @click.stop="removeRow(index)" 
                                        size="small"
                                        color="danger" 
                                        icon-pack="feather" 
                                        icon="icon-trash" 
                                        style="margin-right: 5px"
                                        title="Remove Row"
                                    />
                                    <vs-button 
                                        v-if="index == skuLines.length - 1" 
                                        @click.stop="addRow()" 
                                        size="small"
                                        color="success" 
                                        icon-pack="feather" 
                                        icon="icon-plus" 
                                        title="Add Row"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit" v-if="this.id == 0 ? this.$store.getters['user/hasPermissions']('create') : this.$store.getters['user/hasPermissions']('edit')">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Date from "./component/Date.vue";
import moment from "moment";
import vSelect from "vue-select";
import Swal from "sweetalert2";

export default {
    components: {
        Date,
        vSelect,
        flatPickr
    },
    data() {
        return {
            id: 0,
            readonly: true,
            code: null,
            docStatus: null,
            optionWarehouse: [],
            selectedWarehouse: null,
            optionTerritory: [],
            selectedTerritory: null,
            optionSales: [],
            selectedSales: null,
            requestDate: null,
            approvalReason: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],
            skuLines: [
                {
                    canvas_item_request_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    desc_selected_sku: "",
                    option_hu: [],
                    selected_hu: null,
                    selected_hu_id: null,
                    availableQty: null,
                    raw_available_qty: 0,
                    qty_uom: null,
                    qty: 0
                }
            ],
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData();
        } else {
            this.$http.get("/api/v1/canvas/get-territories")
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.territories) {
                        this.optionTerritory = resp.data.territories
                        if (resp.data.default_territory) {
                            this.selectedTerritory = this.optionTerritory.find((val) => {
                                return val.id == resp.data.default_territory
                            });

                            this.handleGetWarehouseOptions(this.selectedTerritory.id)
                            this.handleGetSalesOptions(this.selectedTerritory.id)
                        }
                    } else {
                        this.optionTerritory = []
                    }
                } else {
                    this.optionTerritory = []
                    this.$vs.dialog({
                        color: "danger",
                        title: "Something went wrong",
                        text: resp.message,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                });
            });
        }
        this.$store.dispatch("user/getPermissions", "canvas-item-request");
    },
    methods: {
        handleSpliceLine() {
            this.skuLines.splice(1)
            this.skuLines.forEach((line) => {
                line.canvas_item_request_line_id = 0
                line.option_sku = []
                line.selected_sku = null
                line.option_hu = []
                line.selected_hu = null
                line.qty_uom = null
                line.availableQty = null
                line.raw_available_qty = 0
                line.qty = 0
            })
        },
        handleGetWarehouseOptions(id) {
            this.$http.get("/api/v1/canvas/get-warehouses/" + id)
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.warehouses) {
                        this.optionWarehouse = resp.data.warehouses
                        if (this.id == 0) {
                            this.selectedWarehouse = this.optionWarehouse[0]
                        }
                    } else {
                        this.optionWarehouse = []
                    }
                } else {
                    this.optionWarehouse = []
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },
        handleGetSalesOptions(id) {
            this.$http.get("/api/v1/canvas/get-sales-options/" + id)
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.sales_options) {
                        this.optionSales = resp.data.sales_options
                    } else {
                        this.optionSales = []
                    }
                } else {
                    this.optionSales = []
                }
            })
            .catch((error) => {
                console.log(error)
            });
        },
        handleGetWarehouseItem(warehouse_id, personal_id, request_date) {
            this.$http.get("/api/v1/canvas/warehouse-items", {
                params: {
                    warehouse_id: warehouse_id,
                    personal_id: personal_id,
                    request_date: this.formatDate(request_date),
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    if (resp.data.records) {
                        this.skuLines.forEach(val => {
                            val.option_sku = resp.data.records
                        })
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetOptionSkuUnit(index, sku_code) {
            this.$http.get("/api/v1/canvas/sku-unit-options", {
                params: {
                    sku_code: sku_code,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.skuLines[index].option_hu = resp.data.options
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetInfoAvailableQty(index, sku_code, unit, warehouse_id) {
            this.$http.get("/api/v1/canvas/get-available-qty", {
                params: {
                    sku_code: sku_code,
                    unit: unit,
                    warehouse_id: warehouse_id
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.skuLines[index].raw_available_qty = resp.data.available_amount;
                    this.skuLines[index].availableQty = this.handleUpdateFormat(resp.data.available_amount);
                    this.skuLines[index].qty_uom = this.handleUpdateFormat(resp.data.amount_uom);
                } else {
                    this.skuLines[index].raw_available_qty = 0;
                    this.skuLines[index].availableQty = 0;
                    this.skuLines[index].qty_uom = 0;
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        getData() {
            this.$vs.loading();
            this.$http.get("/api/v1/canvas/edit/" + this.id)
            .then(resp => {
                if (resp.code == 200) {
                    this.code = resp.data.code;
                    this.docStatus = resp.data.status;
                    this.selectedWarehouse = resp.data.selected_warehouse;
                    this.selectedTerritory = resp.data.selected_territory;
                    this.selectedSales = resp.data.selected_sales;
                    this.requestDate = moment(resp.data.request_date).toDate();
                    this.approvalReason = resp.data.approver_reason
                    this.skuLines = resp.data.sku_lines;
                    this.$vs.loading.close();
                } else {
                    console.log(resp.message)
                    this.$vs.loading.close();
                }

                this.skuLines.forEach(val => {
                    val.availableQty = this.handleUpdateFormat(val.availableQty)
                    val.qty_uom = this.handleUpdateFormat(val.qty_uom)
                })

                for (let i = 0; i < this.skuLines.length; i++) {
                    let skuCode = this.skuLines[i].selected_sku.sku_code
                    let unit = this.skuLines[i].selected_hu.unit

                    this.handleGetOptionSkuUnit(i, skuCode)
                    this.handleGetInfoAvailableQty(i, skuCode, unit, this.selectedWarehouse.id)
                }

                if (this.selectedTerritory.id) {
                    this.handleGetWarehouseOptions(this.selectedTerritory.id)
                    this.handleGetSalesOptions(this.selectedTerritory.id)
                }

                if (this.selectedSales.id) {
                    this.handleGetWarehouseItem(this.selectedWarehouse.id, this.selectedSales.id, this.formatDate(this.requestDate))
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        setDefault() {
            this.id = null;
            this.code = null;
            this.selectedWarehouse = null;
            this.requestDate = null;
            this.skuLines = [
                {
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    availableQty: null,
                    raw_available_qty: 0,
                    qty_uom: null,
                    qty: 0
                }
            ];
        },
        handleBack() {
            let tabOrder
            if (this.docStatus == "Inquiry") {
                tabOrder = 2
            }

            this.$router.push({
                name: "canvas.item-request",
                params: {status: tabOrder}
            });
        },
        onChangeTerritory(val) {
            if (val) {
                this.handleSpliceLine()

                this.optionWarehouse = []
                this.selectedWarehouse = null
                this.optionSales = []
                this.selectedSales = null
                this.selectedTerritory = val

                this.handleGetWarehouseOptions(val.id)
                this.handleGetSalesOptions(val.id)
            }
        },
        onChangeWarehouse(val) {
            if (val) {
                this.handleSpliceLine()
                this.selectedWarehouse = val
                if (this.selectedSales) {
                    this.handleGetWarehouseItem(this.selectedWarehouse.id, this.selectedSales.id, this.formatDate(this.requestDate))
                }
            }
        },
        onChangeSales(val) {
            if (val) {
                this.handleSpliceLine()
                this.selectedSales = val
                this.handleGetWarehouseItem(this.selectedWarehouse.id, this.selectedSales.id, this.formatDate(this.requestDate))
            }
        },
        onChangeLoadingDate(val) {
            this.requestDate = val

            if (this.selectedSales && this.selectedWarehouse) {
                this.handleGetWarehouseItem(this.selectedWarehouse.id, this.selectedSales.id, this.formatDate(this.requestDate))
            }
        },
        handleSubmit() {
            if (!this.selectedWarehouse) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Warehouse is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return false;
            }

            if (!this.selectedTerritory) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Territory is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return false;
            }

            if (!this.selectedSales) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Sales is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return false;
            }

            if (!this.requestDate) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Request Date is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return false;
            }

            if (!this.skuLines.length) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Requested item(s) is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return false;
            }

            let message = "";
            let skus = [];
            this.skuLines.forEach(val => {
                if (!val.selected_sku) {
                    message = "please check sku, sku is required";
                    return;
                }

                if (val.selected_hu == null || val.selected_hu == "" || val.selected_hu == {}) {
                    message = "please check hu, hu is required on item " + val.selected_sku.item_name;
                    return;
                }

                if (val.qty < 1) {
                    message = "qty is required more than zero on item " + val.selected_sku.item_name;
                    return;
                }

                skus.push({
                    canvas_item_request_line_id: val.canvas_item_request_line_id ? val.canvas_item_request_line_id : 0,
                    sku_code: val.selected_sku.sku_code,
                    item_unit: val.selected_hu.unit,
                    item_unit_id: val.selected_hu.item_unit_id,
                    quantity: parseInt(val.qty)
                });
            });

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
                return false;
            }

            let formData = new FormData();
            formData.append(
                "warehouse_id", this.selectedWarehouse.id
            );
            formData.append(
                "territory_id", this.selectedTerritory.id
            );
            formData.append(
                "sales_id", this.selectedSales.id
            );
            formData.append(
                "request_date",
                this.requestDate ? moment(this.requestDate).format("YYYY-MM-DD") : null
            );
            formData.append("skus", JSON.stringify(skus));

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to "+ (this.id ? "update this document?" : "create new document?"),
                accept: () => {
                    this.$vs.notify({
                        color: "warning",
                        title: "Checking..",
                        text: "Checking stock in progress",
                        position: "top-center",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });

                    this.$vs.loading();
                    this.$http.post("/api/v1/canvas/create-or-update/"+ (this.id ? this.id : 0), formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: "Done!",
                                text: "Canvas Document has been " + (this.id ? "updated" : "created"),
                                icon: "success"
                            });
                            this.draw++;
                            this.setDefault();
                            this.handleBack();
                        } else {
                            this.$vs.loading.close();
                            Swal.fire({
                                title: "Something went wrong",
                                text: resp.message,
                                icon: "info"
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: "Something went wrong",
                            text: error,
                            icon: "info"
                        });
                    });
                }
            })
        },
        onChangeSku(val, index) {
            if (val) {
                this.skuLines[index].selected_sku = val;
                this.handleGetOptionSkuUnit(index, val.sku_code)
                this.skuLines[index].selected_hu = null;
                this.skuLines[index].availableQty = null;
                this.skuLines[index].raw_available_qty = 0;
                this.skuLines[index].qty_uom = null;
                this.skuLines[index].qty = 0;
            }
        },
        onSearchSku(search, loading, index) {
            if (search.length) {
                loading(true);
                this.searchSku(loading, search, this, index);
            } else if (search == "") {
                loading(true);
                this.searchSku(loading, "", this, index);
            }
        },
        searchSku: _.debounce((loading, search, t, index) => {
            if (t.selectedSales) {
                t.$vs.notify({
                    color: "warning",
                    title: "Checking",
                    text: "Getting item in process..",
                    position: "top-center",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });

                t.$http.get("/api/v1/canvas/warehouse-items", {
                        params: {
                            warehouse_id: t.selectedWarehouse.id || t.selectedWarehouse.ID,
                            personal_id: t.selectedSales.id || t.selectedSales.ID,
                            search: search,
                            request_date: t.formatDate(t.requestDate),
                        }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            t.skuLines[index].option_sku = resp.data.records;
                        } else {
                            t.skuLines[index].option_sku = [];
                        }
                        loading(false);
                    } else {
                        t.skuLines[index].option_sku = [];
                        loading(false);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
            } else {
                loading(false);
            }
        }, 1000),
        onChangeHu(val, index) {
            if (val) {
                this.skuLines[index].selected_hu = val;
                this.skuLines[index].availableQty = null;
                this.skuLines[index].raw_available_qty = 0;
                this.skuLines[index].qty_uom = null;
                this.skuLines[index].qty = 0;

                this.handleGetInfoAvailableQty(index, this.skuLines[index].selected_sku.sku_code, this.skuLines[index].selected_hu.unit, this.selectedWarehouse.id)
            }
        },
        onChangeQty(val, index) {
            this.skuLines[index].qty = val;
            if (this.skuLines[index].qty < 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Quantity need more than zero",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });

                this.skuLines[index].qty = 0;
            }
        },
        removeRow(index) {
            this.skuLines.splice(index, 1);
        },
        addRow() {
            this.skuLines.push({
                canvas_item_request_line_id: 0,
                option_sku: [],
                selected_sku: null,
                option_hu: [],
                selected_hu: null,
                qty_uom: null,
                availableQty: null,
                raw_available_qty: 0,
                qty: 0
            });

            if (this.selectedWarehouse != null) {
                this.handleGetWarehouseItem(this.selectedWarehouse.id, this.selectedSales.id, this.formatDate(this.requestDate))
            }
        }
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
};
</script>
